.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 0 20px;
}

.logo {
  width: 80%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
  margin-top: -80px;
}

.description {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

.link-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.link {
  display: flex;
  align-items: center;
  color: #333;
  margin: 10px;
  font-size: 1.2em;
  transition: color 0.3s;
}

.icon {
  margin-right: 8px;
}

.link:hover {
  color: #369BA1;
}

.footer {
  font-size: 0.8em;
  color: #ffffff;
  margin-top: auto;
  padding: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100%;
    max-width: 700px;
  }

  .link-bar {
    margin-top: 10px;
  }

  .footer {
    color: black;
  }
  body {
    background-position: center bottom;
    background-size: 100% 70%;
    margin: 0;
    padding: 0;
  }
}
